import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
    ISubmitProvider,
    ProviderChannelService,
    ProviderPractice,
    ProviderChannelHealthcareExperience,
    ProviderChannelEducation,
    AddtionalList,
    IProfileFormSubmit,
} from 'types/channelApplicationProvider';
import Profile from 'components/ApplicationFormComponents/Profile';
import Services from 'components/ApplicationFormComponents/Services';
import Practice from 'components/ApplicationFormComponents/Practice';
import HealthcareExperience from 'components/ApplicationFormComponents/HealthcareExperience';
import Education from 'components/ApplicationFormComponents/Education';
import AdditionalInformation from 'components/ApplicationFormComponents/AdditionalInformation';
import License from 'components/ApplicationFormComponents/License';
import s from './s.module.less';
import { StepValue } from 'pages/CreateUniProfilePage/types';
import globalFormsEditStatusStore from 'store/ChannelApplicationProvider/FormsEditStatus';
import { NoticeType } from 'antd/es/message/interface';
// import { Review } from 'types/common';
import Insurance from 'components/ApplicationFormComponents/Insurance';
import AddInformationCard from '../AddInformationCard';
// import MigrationCard from '../MigrationCard';
import WaitApproveCard from '../WaitApproveCard';
import GoLiveCard from '../GoLiveCard';
import { message, Tabs } from 'antd';
import Notes from '../Notes';
import Assignee from '../Assignee';
import { UniprofileFormData } from 'types/provider';
import Biographic from 'components/ApplicationFormComponents/Biographic';
import AccountInfo from '../AccountInfo';
import { isFreeUserByInfo } from 'utils/provider';
import LocalSEO from 'components/PracticeFrontFromComponents/LocalSEO';
import useProviderServiceFlag from 'hooks/useProviderServiceFlag';
import KlarityServices from 'components/ApplicationFormComponents/KlarityServices';
import copy from 'copy-to-clipboard';

type ISubmitData = Partial<IProfileFormSubmit>;

type FormData = {
    profile: Partial<ISubmitProvider>,
    services: Array<ProviderChannelService>,
    practice: ProviderPractice,
    experienceList: Array<ProviderChannelHealthcareExperience>,
    educationList: Array<ProviderChannelEducation>,
    addtional: {
        payout: string;
        showReview: boolean;
        associationAdditionalList: Array<AddtionalList>;
        awardAdditionalList: Array<AddtionalList>;
        publicationAdditionalList: Array<AddtionalList>;
    },
};

interface IProps {
    providerId: number;
    // reviews: Array<Review>,
    channelId: number;
    formData: UniprofileFormData;
    onSubmit: (value: ISubmitData, step: StepValue) => void;
    refetch: () => void;
    // onShowReviewModal: () => void;
    onShowMessage: (type: NoticeType, content: string) => void;
    onApprove: () => void;
    isDrawer?: boolean;
    showSyncButton?: boolean;
    onClickSync?: () => void;
}

const Detail = forwardRef(({
    isDrawer,
    providerId,
    channelId,
    formData,
    // reviews,
    onSubmit,
    refetch,
    // onShowReviewModal,
    onShowMessage,
    onApprove,
    showSyncButton,
    onClickSync,
}: IProps, ref) => {
    const isUniprofileDetail = channelId === -1;
    const [showEditButtons, setShowEditButtons] = useState<boolean>(false);
    const [editEnableMap, setEditEnableMap] = useState({
        [StepValue.PROFILE]: false,
        [StepValue.SERVICES]: false,
        [StepValue.PRACTICE]: false,
        [StepValue.HEALTHCARE_EXPERIENCE]: false,
        [StepValue.EDUCATION]: false,
        [StepValue.ADDITIONAL_INFORMATION]: false,
        [StepValue.INSURANCE]: false,
        [StepValue.BIOGRAPHIC]: false,
        [StepValue.LICENSE]: false,
    });
    const [notesIsEditing, setNotesIsEditing] = useState(false);
    const [showNotesError, setShowNotesError] = useState(false);
    const [tabActiveKey, setTabActiveKey] = useState('1');

    const [getGlobalEditStatus, setGloalEditStatus] = globalFormsEditStatusStore.useStore();
    const globalFormsEditStatus = getGlobalEditStatus('globalEditStatus');

    const { profile, services, practice, experienceList, educationList, addtional, insurance, licenses, physicians, assignee = '', malpracticeInsuranceCertificate, notes = '', biographic, payPlan, listedChannels, seoData, containSeoApp, appointmentIntegrationUrl, avaAutoReply, channelAccount, channelPassword, listingType, deaLicensePrescribeStatus } = formData;
    console.log('profile: ', profile);

    const auditStatus = profile?.auditStatus;
    const initPracticeName = useMemo(
        () => `${profile.firstName} ${profile.lastName}'s Practice`,
        [profile],
    );

    const [uniProfileFlag] = useProviderServiceFlag(true, providerId);

    useEffect(() => {
        if (isUniprofileDetail) {
            setShowEditButtons(profile.allowAuth !== false);
        } else {
            setShowEditButtons(false);
        }
    }, [isUniprofileDetail, profile]);

    const handleStepEditStatusChange = useCallback((status: boolean, step: StepValue, callback?: () => void) => {
        if (!globalFormsEditStatus || !status) {
            setEditEnableMap({
                ...editEnableMap,
                [step]: status,
            });
            callback?.();

            setGloalEditStatus('globalEditStatus', status);
        } else {
            onShowMessage('error', 'Please save your changes before proceeding!');
        }
    }, [editEnableMap, globalFormsEditStatus, onShowMessage, setGloalEditStatus]);

    const handleCopyPassword = useCallback(() => {
        if (channelPassword) {
            copy(channelPassword || '', { format: 'text/plain' });
            message.success('Copied to the clipboard');
        }
    }, [channelPassword]);

    useImperativeHandle(ref, () => ({
        setStepEditStatus: handleStepEditStatusChange,
    }));

    // const handleStepSubmit = useCallback((formValue: any, step: StepValue) => {
    //     if (typeof onSubmit === 'function') {
    //         onSubmit(formValue, step);
    //     }
    // }, [onSubmit]);

    const renderOpsCard = useMemo(() => {
        if (!isUniprofileDetail && profile?.id) {
            if (auditStatus === 'upload-data') {
                // return <MigrationCard />;
            } else if (auditStatus === 'waiting-approve') {
                return (
                    <WaitApproveCard
                        channelId={channelId}
                        providerId={profile.id}
                        refetch={refetch}
                        providerEmail={profile.email}
                        isGoogleChannel={profile.googleChannel}
                        isNeedAccount={profile.needAccount}
                    />
                );
            } else if (auditStatus === 'approved') {
                return <GoLiveCard channelId={channelId} providerId={profile.id} website={profile.website} refetch={refetch} />;
            }
        }
    }, [auditStatus, channelId, isUniprofileDetail, profile, refetch]);

    useEffect(() => {
        setTabActiveKey(renderOpsCard ? '1' : '2');
    }, [renderOpsCard]);

    const handleNavMenuClick = useCallback((key: string) => {
        const element = document.getElementById(key);

        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, []);

    const showOpsPanel = useMemo(() => {
        return profile?.id && !isDrawer;
    }, [isDrawer, profile]);

    const navMenuItems = useMemo(() => {
        const items = [
            {
                key: 'profile',
                label: 'Profile',
            },
            {
                key: 'services',
                label: 'Services',
            },
            // {
            //     key: 'specialtiesConditions',
            //     label: 'Specialties & Conditions',
            // },
            // {
            //     key: 'treatingMethodology',
            //     label: 'Treatment methodology',
            // },
            {
                key: 'practice',
                label: 'Practice',
            },
            // {
            //     key: 'practiceAddress',
            //     label: 'Practice address',
            // },
            {
                key: 'healthcareExperience',
                label: 'Healthcare experience',
            },
            {
                key: 'education',
                label: 'Education',
            },
            {
                key: 'additionalInformation',
                label: 'Additional information',
            },
            // {
            //     key: 'professionalMemberships',
            //     label: 'Professional memberships or associations',
            // },
            // {
            //     key: 'awardsAchievements',
            //     label: 'Awards or achievements',
            // },
            // {
            //     key: 'publications',
            //     label: 'Publications',
            // },
            {
                key: 'insurance',
                label: 'Insurance',
            },
            {
                key: 'license',
                label: 'Licences',
            },
            // {
            //     key: 'malpracticeInsuranceCertificate',
            //     label: 'Malpractice insurance certificate',
            // },
            // {
            //     key: 'deaLicenses',
            //     label: 'DEA Licenses',
            // },
            // {
            //     key: 'collaboratingPhysician',
            //     label: 'Collaborating Physician',
            // },
            // {
            //     key: 'additionalLicencesCertificates',
            //     label: 'Additional licences and certificates',
            // },
            {
                key: 'biographicInformation',
                label: 'Biographic information',
            },
        ];

        if (uniProfileFlag?.klarityServiceFlag) {
            items.unshift({
                key: 'klarityServices',
                label: 'Klarity services',
            });
        }

        if (isUniprofileDetail) {
            items.unshift({
                key: 'accountInformation',
                label: 'Account information',
            });
        }

        if (showOpsPanel) {
            items.unshift({
                key: 'opsPanel',
                label: 'Ops panel',
            });
        }

        return items;
    }, [isUniprofileDetail, uniProfileFlag, showOpsPanel]);

    const tabItems = [
        !isUniprofileDetail && {
            key: '1',
            label: 'Status',
            disabled: !renderOpsCard,
            children: renderOpsCard,
        },
        {
            key: '2',
            label: 'Notes',
            children: <Notes notes={notes} channelId={channelId} providerId={providerId} isEditing={notesIsEditing} showError={showNotesError} onClick={(stats: boolean) => setNotesIsEditing(stats)} refetch={refetch} />,
        },
        {
            key: '3',
            label: 'Assignee',
            children: <Assignee assignee={assignee} channelId={channelId} providerId={providerId} refetch={refetch} />,
        },
    ].filter(Boolean);

    const handleTabClick = useCallback((key: string) => {
        setTabActiveKey(key);
    }, []);

    const isFree = isFreeUserByInfo(payPlan);

    return (
        <div className={s.detailBox}>
            {/* {
                !isFree &&
                <div className={s.block}>
                    <LocalSEO
                        initFormValue={seoData}
                        containSeoApp={containSeoApp}
                        practiceAddress={practice.addressList}
                        payPlan={payPlan}
                        isOps
                        providerId={providerId}
                    />
                </div>
            } */}
            {
                showOpsPanel &&
                <div className={`${s.block} ${s.opsCardBox} ${s.fixedBlock}`}>
                    <div id="opsPanel" className={s.anchor} />
                    <div className={s.cardInner}>
                        <h3>Ops panel</h3>
                        <Tabs activeKey={tabActiveKey} items={tabItems as any} onTabClick={handleTabClick} />
                    </div>
                </div>
            }
            <div className={s.navMenu}>
                <Tabs defaultActiveKey="accountInformation" tabPosition="top" items={navMenuItems} onChange={handleNavMenuClick} />
            </div>
            {
                listingType === 'M' && (channelAccount || channelPassword) && (
                    <div className={`${s.block} ${s.opsCardBox}`}>
                        <div className={s.cardInner}>
                            <h3 className={s.titleWithBorderLine}>{'Provider\'s existing account'}</h3>
                            <div className={s.row}>
                                {
                                    channelAccount && (
                                        <div
                                            className={s.displayItem}
                                            style={{ flex: 1 }}
                                        >
                                            <div className={s.label}>User name</div>
                                            <div className={s.value}>{channelAccount}</div>
                                        </div>
                                    )
                                }
                                {
                                    channelPassword && (
                                        <div
                                            className={s.displayItem}
                                            style={{ flex: 1 }}
                                        >
                                            <div className={s.label}>Password</div>
                                            <div className={s.value}>
                                                <div className={s.text}>********</div>
                                                <div className={s.copyIcon} onClick={handleCopyPassword} />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            {
                isUniprofileDetail &&
                <div className={s.block}>
                    <div id="accountInformation" className={s.anchor} />
                    <AccountInfo
                        refetch={refetch}
                        payplan={payPlan}
                        listedChannels={listedChannels}
                        auditDetailData={formData}
                    />
                </div>
            }
            <div className={s.block}>
                <div id="profile" className={s.anchor} />
                <Profile isOps providerId={providerId} channelId={channelId} enablePhotoSelect={isUniprofileDetail} showEditButtons={showEditButtons && !isDrawer} editable={editEnableMap[StepValue.PROFILE]} initFormValue={profile} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} refetch={refetch} />
            </div>
            <div className={s.block}>
                <div id="services" className={s.anchor} />
                <Services isOps providerId={providerId} addressList={practice?.addressList} onShowMessage={onShowMessage} showEditButtons={showEditButtons} initFormValue={services} refetch={refetch} />
            </div>
            {/* {
                ((uniProfileFlag && uniProfileFlag.kiwiServiceFlag) || !uniProfileFlag) &&
                <div className={s.block}>
                    <Services isOps providerId={providerId} addressList={practice?.addressList} onShowMessage={onShowMessage} showEditButtons={showEditButtons && !isDrawer} initFormValue={services} refetch={refetch} />
                </div>
            } */}

            {
                uniProfileFlag?.klarityServiceFlag &&
                <div className={s.block}>
                    <div id="klarityServices" className={s.anchor} />
                    <KlarityServices showSyncButton={showSyncButton} onClickSync={onClickSync} addressList={practice.addressList} isOps providerId={providerId} showEditButtons={showEditButtons && !isDrawer} licenses={licenses} refetch={refetch} isReviewStep />
                </div>
            }
            {/* <div className={s.block}>
                <KlarityServices addressList={practice.addressList} isOps providerId={providerId} showEditButtons={showEditButtons} licenses={licenses} refetch={refetch} isReviewStep />
            </div> */}

            <div className={s.block}>
                <div id="practice" className={s.anchor} />
                <Practice isOps showEditButtons={showEditButtons && !isDrawer} editable={editEnableMap[StepValue.PRACTICE]} initFormValue={{ ...practice, appointmentIntegrationUrl, avaAutoReply }} initPracticeName={initPracticeName} onSubmit={onSubmit} refetch={refetch} onEditableChange={handleStepEditStatusChange} />
            </div>
            <div className={s.block}>
                <div id="healthcareExperience" className={s.anchor} />
                <HealthcareExperience showEditButtons={showEditButtons && !isDrawer} editable={editEnableMap[StepValue.HEALTHCARE_EXPERIENCE]} initFormValue={experienceList} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} />
            </div>
            <div className={s.block}>
                <div id="education" className={s.anchor} />
                <Education showEditButtons={showEditButtons && !isDrawer} editable={editEnableMap[StepValue.EDUCATION]} initFormValue={educationList} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} />
            </div>
            <div className={s.block}>
                <div id="additionalInformation" className={s.anchor} />
                <AdditionalInformation showEditButtons={showEditButtons && !isDrawer} editable={editEnableMap[StepValue.ADDITIONAL_INFORMATION]} initFormValue={addtional} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} refetch={refetch} />
            </div>
            <div className={s.block}>
                <div id="insurance" className={s.anchor} />
                <Insurance isOps showEditButtons={showEditButtons && !isDrawer} editable={editEnableMap[StepValue.INSURANCE]} initFormValue={insurance} onSubmit={onSubmit} onEditableChange={handleStepEditStatusChange} />
            </div>
            <div className={s.block}>
                <div id="license" className={s.anchor} />
                <License
                    deaLicensePrescribeStatus={deaLicensePrescribeStatus}
                    editable={editEnableMap[StepValue.LICENSE]}
                    initLicenseFormValue={licenses}
                    initPhysicianFormValue={physicians}
                    initMalpracticeInsuranceCertificateValue={malpracticeInsuranceCertificate}
                    onSubmit={onSubmit}
                    onEditableChange={handleStepEditStatusChange}
                    isOps
                    providerId={providerId}
                    viewOnly={!showEditButtons || isDrawer}
                    refetch={refetch}
                />
            </div>
            <div className={s.block}>
                <div id="biographicInformation" className={s.anchor} />
                <Biographic
                    showEditButtons={showEditButtons && !isDrawer}
                    editable={editEnableMap[StepValue.BIOGRAPHIC]}
                    initFormValue={biographic}
                    onSubmit={onSubmit}
                    onEditableChange={handleStepEditStatusChange}
                />
            </div>
            {
                !isFree &&
                <div className={s.block}>
                    <LocalSEO
                        initFormValue={seoData}
                        containSeoApp={containSeoApp}
                        practiceAddress={practice.addressList}
                        payPlan={payPlan}
                        isOps
                        providerId={providerId}
                        refetch={refetch}
                    />
                </div>
            }
            {
                auditStatus === 'upload-data' && <AddInformationCard onClick={onApprove} />
            }
        </div>
    );
});

export default Detail;
