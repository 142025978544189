import { Button, Input, Radio, RadioChangeEvent, Space, message, Select } from 'antd';
import React, { ChangeEvent, useCallback, useState, useEffect } from 'react';
import s from './s.module.less';
import { updateProviderChannelAuditStatus, getGoogleTitleList } from 'api/operation';
import type { UpdateProviderChannelAuditStatusInput } from 'types/operation';
import type { SelectProps } from 'antd';

interface IProps {
    providerEmail?: string;
    providerId?: number;
    channelId: number;
    isGoogleChannel?: boolean;
    isNeedAccount?: boolean;
    refetch: () => void;
}

const WaitApproveCard = ({
    providerEmail,
    providerId,
    channelId,
    isGoogleChannel,
    isNeedAccount,
    refetch,
}: IProps) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [radionValue, setRadioValue] = useState('0');
    const [inputValue, setInputValue] = useState('');
    const [googleTitleValue, setGoogleTitleValue] = useState<string | undefined>(undefined);
    const [accountValue, setAccountValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [googleTitleList, setGoogleTitleList] = useState<SelectProps['options']>([]);

    const handleRadioChange = useCallback((e: RadioChangeEvent) => {
        setRadioValue(e.target.value);
    }, []);

    const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    }, []);

    const handleGoogleTitleChange = useCallback((newValue: string) => {
        setGoogleTitleValue(newValue);
    }, []);

    const handleAccountChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setAccountValue(e.target.value);
        },
        [],
    );

    const handlePasswordChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setPasswordValue(e.target.value);
        },
        [],
    );

    const handleUpdate = useCallback(
        async ({
            passed,
            website,
            account,
            password,
            googleTitle,
        }: {
            passed: boolean;
            website?: string;
            account?: string;
            password?: string;
            googleTitle?: string;
        }) => {
            if (providerId) {
                let params: UpdateProviderChannelAuditStatusInput = {
                    channelId,
                    providerId,
                    passed,
                    website,
                };
                if (isGoogleChannel && isNeedAccount) {
                    params = {
                        ...params,
                        googleTitle,
                    };
                } else if (!isGoogleChannel && isNeedAccount) {
                    params = {
                        ...params,
                        account,
                        password,
                    };
                }
                const res = await updateProviderChannelAuditStatus(params);

                if (!res?.error) {
                    refetch();
                } else {
                    messageApi.error(res?.error);
                }
            }
        },
        [channelId, messageApi, providerId, refetch],
    );

    const handleReject = useCallback(async () => {
        handleUpdate({
            passed: false,
        });
        window.open(`mailto:${providerEmail}`);
    }, [handleUpdate, providerEmail]);

    const handleGoLive = useCallback(async () => {
        handleUpdate({
            passed: true,
            website: inputValue,
            account: accountValue,
            password: passwordValue,
            googleTitle: googleTitleValue,
        });
    }, [handleUpdate, inputValue, accountValue, passwordValue, googleTitleValue]);

    const getList = useCallback(async () => {
        const { data } = await getGoogleTitleList();
        setGoogleTitleList(data.data.map((item) => {
            return {
                value: item,
                label: item,
            };
        }));
        console.log('data: ', data);
    }, []);

    useEffect(() => {
        getList();
    }, []);

    return (
        <div className={s.wrap}>
            {contextHolder}
            <div className={s.title}>
                Has the provider's application been approved by the channel?
            </div>
            <div className={s.selectBox}>
                <Radio.Group value={radionValue} onChange={handleRadioChange}>
                    <Space direction="vertical">
                        <Radio value="0">
                            No, need to contact provider for updating UniProfile.
                        </Radio>
                        <Radio value="1">
                            Yes, here is the profile link for the channel.
                        </Radio>
                    </Space>
                </Radio.Group>
                {radionValue === '1' && (
                    <div style={{ marginTop: '8px' }} className={s.formList}>
                        <Input
                            style={{ width: '300px' }}
                            value={inputValue}
                            placeholder="www.example.com"
                            onChange={handleInputChange}
                        />
                        {isGoogleChannel && isNeedAccount && (
                            <Select
                                showSearch
                                style={{ width: '300px' }}
                                placeholder="Select a provider"
                                value={googleTitleValue}
                                onChange={handleGoogleTitleChange}
                                options={googleTitleList}
                                defaultActiveFirstOption={false}
                            />
                        )}
                        {!isGoogleChannel && isNeedAccount && (
                            <>
                                <div className={s.label}>Account name:</div>
                                <Input
                                    style={{ width: '300px' }}
                                    value={accountValue}
                                    placeholder="Account name"
                                    onChange={handleAccountChange}
                                />
                                <div className={s.label}>Password:</div>
                                <Input
                                    style={{ width: '300px' }}
                                    value={passwordValue}
                                    placeholder="Password"
                                    onChange={handlePasswordChange}
                                />
                            </>
                        )}
                    </div>
                )}
            </div>
            {radionValue === '0' ? (
                <Button type="primary" ghost onClick={handleReject}>
                    Contact provider
                </Button>
            ) : (
                <Button type="primary" ghost onClick={handleGoLive}>
                    Update status to Listing go-live
                </Button>
            )}
        </div>
    );
};

export default WaitApproveCard;
