import { ELicenseType, License, Physician, Service, LocalSEOInfoServer, EditLocalSEOInfoServer, TProfileLinkInfo, EServiceType, EListStatus } from './common';
import { FormPhysician, FormLicense } from 'types/form';
import { AddtionalList, HealthcareExperienceFieldEnum, ProviderChannelEducation, ProviderChannelHealthcareExperience } from 'types/channelApplicationProvider';
import { PracitceAddressForm } from 'pages/CreateUniProfilePage/types';
import { TSubscriptionItem, TSubscriptionItemFromServer, TSubscriptionType } from './subscription';
import { EProfileStatus, MyChannelsData } from './channel';
import { EBindType } from './salesProvider';
import dayjs from 'dayjs';
import { ESource } from './operation';

interface IChannelListChannel {
    id: number;
    name: string;
}

export enum EProviderInfoStatus {
    IN_PROGRESS = 'In-Progress',
    FINISHED_SIGN_UP = 'Finished Sign-up',
    SUBMITTED_APPLICATION = 'Submitted Application',
    APPLICATION_ON_HOLD = 'Application on-hold',
    APPLICATION_APPROVED = 'Application approved',
    BP_ACCOUNT_ENTERED = 'BP Account Entered',
}
export interface IproviderCreate {
    email?: string
    firstName?: string
    gmail?: string
    lastName?: string
    tel?: string
}

export interface ProviderBasicInfo {
    id: number;
    address: string;
    specialityInHealth: string; //'ADHD,Anxiety',
    specialityInFamily: string; //'ADHD,Anxiety',
    npi?: string;
    state: string;
    title?: string;
    yearExp: number;
    tel?: string;
    zip: string;
    status?: EProviderInfoStatus;
    email?: string;
    gmail?: string;
    gmailPassword?: string;
    editable?: string;
    createTime?: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    city?: string;
    refreshToken?: string;
    intakeqProviderId?: string;
    levelType?: string;
    photo: string;
    credential: string;
    useIntegrationUrl?: boolean;
    appointmentIntegrationUrl?: string;
}

export type Provider = ProviderBasicInfo & {
    licenseList?: License[],
    physicianData?: Physician[],
    googleAvatar?: string,
};

type Modify<T, R> = Omit<T, keyof R> & R;

export type ProviderProfile = Modify<ProviderBasicInfo, {
    title?: string,
    intro?: string,
    primaryLanguage?: string,
    foreignLanguage?: string[],
    inPersonChecked?: boolean,
    officeAddress?: string,
    officeCity?: string,
    officeState?: string,
    officeZip?: string,
    payout?: string,
    payment?: string,
    showReview?: boolean,
    allowAuth?: boolean,
    photoList?: string[],
    additionalPhotoList?: string[],
    inPersonVisitList?: unknown[],
    telehealthVisitList?: unknown[],
    licenses?: string[], //nurse string display?
    status?: string,
    listStatus?: string,
    auditStatus?: string,
    updateStatus?: string,
    photo?: string,
    website?: string,
    middleName?: string,
    headLine?: string,
    headLines?: string[],
    credential?: string[],
    specialityInHealth: string[],
    specialityInFamily: string[],
    npiNotRequired: boolean,
    hasEhrAccountPwd?: boolean,
    product?: string,
    channelPreference?: string,
    signUpFrom?: ESource,
    headLineList?: string[],
    googleChannel: boolean,
    needAccount: boolean
}>;

export type ProviderListItem = ProviderBasicInfo;

export enum ProfileFormFieldEnum {
    EMAIL = 'email',
    GMAIL = 'gmail',
    TEL = 'tel',
    MAILING_ADDRESS = 'address',
    CITY = 'city',
    ZIP_CODE = 'zip',
    STATE = 'state',
    NPI_NUMBER = 'npi',
    YEARS_OF_EXPERIENCE = 'yearExp',
    SPECIALITYINHEALTH = 'specialityInHealth',
    SPECIALITYINFAMILY = 'specialityInFamily',
}

export interface ProfileFormInterface {
    [ProfileFormFieldEnum.EMAIL]: string;
    [ProfileFormFieldEnum.GMAIL]?: string;
    [ProfileFormFieldEnum.TEL]: string;
    [ProfileFormFieldEnum.MAILING_ADDRESS]: string;
    [ProfileFormFieldEnum.CITY]: string;
    [ProfileFormFieldEnum.ZIP_CODE]: string;
    [ProfileFormFieldEnum.STATE]: string;
    [ProfileFormFieldEnum.NPI_NUMBER]: string;
    [ProfileFormFieldEnum.YEARS_OF_EXPERIENCE]: number;
    [ProfileFormFieldEnum.SPECIALITYINHEALTH]: string[];
    [ProfileFormFieldEnum.SPECIALITYINFAMILY]: string[];
}

export enum QualificationFormFieldEnum {
    STATE = 'state',
    DEA_LICENSES = 'deaLicense',
    NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES = 'nurseAndPhysicianAssistantLicenses',
    CONTROLLED_SUBSTANCE_CERTIFICATION = 'controlledSubstanceCertification',
    CONTINUING_MEDICAL_EDUCATION_CERTIFICATES = 'continuingMedicalEducationCertificates',
    COLLABORATING_PHYSICIAN = 'collaboratingPhysician',
}

export type NurseAndPaFormLicenses = {
    nurse?: Partial<FormLicense>,
    pa?: Partial<FormLicense>
};

export type NurseAndPaLicenses = {
    nurse?: Partial<License>,
    pa?: Partial<License>
};

export type FormMutipleLicense = {
    licenses?: Partial<FormLicense | FormLicenseShadow>[];
    needLicense?: string;
};

export type MutipleLicense = {
    licenses?: Partial<License>[]
};

export interface QualificationFormFieldEnumInterface {
    [QualificationFormFieldEnum.STATE]: string;
    [QualificationFormFieldEnum.DEA_LICENSES]: FormMutipleLicense;
    [QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES]: NurseAndPaFormLicenses;
    [QualificationFormFieldEnum.CONTROLLED_SUBSTANCE_CERTIFICATION]: FormMutipleLicense;
    [QualificationFormFieldEnum.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]: FormMutipleLicense;
    [QualificationFormFieldEnum.COLLABORATING_PHYSICIAN]: FormPhysician;
}

export interface QualificationFieldEnumInterface {
    [QualificationFormFieldEnum.STATE]: string;
    [QualificationFormFieldEnum.DEA_LICENSES]: MutipleLicense;
    [QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES]: NurseAndPaLicenses;
    [QualificationFormFieldEnum.CONTROLLED_SUBSTANCE_CERTIFICATION]: MutipleLicense;
    [QualificationFormFieldEnum.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]: MutipleLicense;
    [QualificationFormFieldEnum.COLLABORATING_PHYSICIAN]: Physician;
}
export interface FormMutipleQualificationFormFieldEnumInterface {
    qualifications: Partial<QualificationFormFieldEnumInterface>[]
}

export interface PreviewFormInterface extends ProfileFormInterface, FormMutipleQualificationFormFieldEnumInterface {

}

export type ProviderPractice = {
    id?: number;
    treatmentPhilosophy: string;
    treatmentApproach: string;
    practiceName: string;
    practiceAddress: string;
    practiceCity: string;
    practiceState: string;
    practiceZip: string;
    practiceEmail: string;
    practicePhone: string;
    practiceWebsite: string;
    companyType: string;
    practiceDesc: string;
    practiceLogo: string;
    specialityInFamily?: string[];
    specialityInHealth?: string[];
    practiceFrontUrl: string;
    specialtyList: string[],
    conditionTreatedList: string[],
    addressList?: Array<PracitceAddressForm>,
    notHaveName: boolean,
    notHavePhone: boolean,
    specialtyHighlightList?: string[];
    taxIdType: string;
    taxId: string;
    setUpLapOption: string;
    w9Form: string;
    vfdPhoneLine?: string;
};

export type ProviderExperience = {
    organizationName: string;
    title: string;
    startDate: string;
    endDate: string;
    employmentType: string;
    hospitalLocation: string;
    description: string;
    experienceLogo: string;
    currently: boolean;
};

export type ProviderEducation = {
    schoolName: string;
    major: string;
    degree: string;
    startDate: string;
    endDate: string;
    specialties: string;
    certificates: string;
    schoolLogo: string;
    currently: boolean;
    id: number;
};

export type AwardAdditional = {
    id: string;
    name: string;
    website: string;
    authors: string;
    additionType: string;
};

export type TKlarityServiceServerItemForPF = {
    serviceId: number;
    serviceSpecialtyName: string;
    serviceSpecialtyId: number;
    serviceTypeId: number;
    serviceType: EServiceType;
    displayName: string;
    providerId: number;
    customizedServiceName?: string;
    state: string; //'NY',
    duration: number;
    earning: number;
    patientCost: number;
    teleHealth: boolean;
    inPerson: boolean;
    appliedTeleHealth: boolean;
    appliedInPerson: boolean;
    groupAndLimitationId: number;
    qtyPatientGroup: number;
    qtyLimitation: number;
    allPatientGroup: boolean;
    avaPriceInsight?: number;
    serviceSpecialtyImage?: string;
    serviceDescription?: string;
    offeringId?: number;
    limitation?: string;
    patientGroup?: string;
    description: string;
    priceUpdateAt: string;
    serviceSpecialtyDescription: string;
};

export type UniProfile = {
    appointmentIntegrationUrl: string;
    useIntegrationUrl: boolean;
    appointmentNewFlag: boolean | null;
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    tel: string;
    gmail: string;
    address: string;
    zip: string;
    state: string;
    city: string;
    status: string;
    editable: string;
    refreshToken: string;
    npi: string;
    yearExp: number;
    specialityInHealth: string;
    specialityInFamily: string;
    createTime: string;
    timezone: string;
    intakeqProviderId: string;
    providerId: number;
    channelId: number;
    title: string;
    intro: string;
    primaryLanguage: string;
    foreignLanguage: string[];
    payout: string;
    showReview: boolean;
    allowAuth: boolean;
    listStatus: string;
    auditStatus: string;
    photo: string;
    photoList: string[];
    additionalPhotoList: string[];
    providerServiceList: Service[];
    providerPractice: ProviderPractice;
    providerExperienceList: ProviderExperience[];
    providerEducationList: ProviderEducation[];
    associationAdditionalList: unknown[];
    awardAdditionalList: AwardAdditional[];
    publicationAdditionalList: [];
    licenseDtoList: Array<License>;
    credential: string;
    headLine: string;
    supportPayType: string;
    containSeoApp?: boolean,
    seoData?: Record<string, EditLocalSEOInfoServer>, //key is state
    pfKlarityServiceList?: TKlarityServiceServerItemForPF[],
};

export interface PFProvider extends Omit<UniProfile, 'providerServiceList'> {
    insuranceList: Array<string>;
    otherInsuranceList: Array<string>;
    channelList: Array<IChannelListChannel>;
    seoCity?: string;
    pfKlarityServiceList?: TKlarityServiceServerItemForPF[];
    providerServiceList: Array<{
        id?: number;
        colorId?: string;
        description?: string;
        duration?: number;
        name: string;
        personAddress?: string;
        personCity?: string;
        personState?: string;
        personType?: string[];
        personZip?: string;
        providerId?: number;
        sendForm?: string;
        telehealthType?: string[];
        type?: string;
        price?: number;
        intakeForm: string;
    }>
}

export type ProviderLicenseFromData = {
    state: string;
    [ELicenseType.DEA]?: Array<FormLicense>;
    [ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]?: Array<FormLicense>;
    [ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION]?: Array<FormLicense>;
    [ELicenseType.PRACTICE]?: Array<FormLicense>;
};

export type ProviderLicenseFromDataWithoutState = {
    [ELicenseType.DEA]?: Array<FormLicense>;
    [ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]?: Array<FormLicense>;
    [ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION]?: Array<FormLicense>;
    [ELicenseType.PRACTICE]?: Array<FormLicense>;
};

export type ProviderPhysicianFromData = {
    state: string;
    collaboratingPhysician?: FormPhysician;
};

export interface IContract {
    contractId: number;
    contractPlanId?: number;
    contractPlanName?: string;
    contractSignAddress: string;
    contractSignAt: string;
    contractSignDate: string;
    contractSignName: string;
    contractSignUrl: string;
    contractVersion: string;
    providerId: number;
    sourceId: number;
    sourceType: string;
}

export type ServiceExt = Service & { inpersonChecked: boolean, telehealthChecked: boolean };

export type ServiceExtShadow = ServiceExt & {
    isNewAdd?: boolean,
    isDeleted?: boolean,
    descriptionNew?: string;
    durationNew?: number;
    nameNew?: string;
    personAddressNew?: string;
    personCityNew?: string;
    personStateNew?: string;
    personTypeNew?: string;
    personZipNew?: string;
    priceNew?: number;
    lowestPriceNew?: number;
    highestPriceNew?: number;
};

export type TPreviousListing = {
    id: number,
    providerId: number,
    channelName: string, // "Psychology Today",
    channelId: number,
    account: string, // "evanliu.it2@gmail.com",
    password: string,
    createAt: string, //"2024-07-09T00:43:38.000+00:00",
    createBy: number,
    updateAt: string, //"2024-07-09T00:43:38.000+00:00",
    updateBy: number, // 51
};

export type UniprofileFormData = {
    id: number;
    assignee?: string;
    notes?: string;
    listChannelCount?: number;
    profile: Partial<ProviderProfile>,
    profileLinks?: string[],
    profileLinkInfo?: TProfileLinkInfo[],
    services: ServiceExt[],
    practice: ProviderPractice,
    experienceList: Array<ProviderChannelHealthcareExperience>,
    educationList: Array<ProviderChannelEducation>,
    conversationId?: string,
    previousListings?: TPreviousListing[],
    signUpFrom?: ESource,
    appointmentIntegrationUrl?: string,
    avaAutoReply?: boolean,
    channelPassword?: string,
    channelAccount?: string,
    listingType?: 'M' | 'L',
    documentId?: string,
    deaLicensePrescribeStatus?: string,
    addtional: {
        payout: string;
        showReview: boolean;
        associationAdditionalList: Array<AddtionalList>;
        awardAdditionalList: Array<AddtionalList>;
        publicationAdditionalList: Array<AddtionalList>;
    },
    insurance: {
        insuranceList: Array<string>;
        otherInsuranceList: string;
        supportPayType: string;
    }
    biographic: {
        ethnicity: string;
        birthDate: string;
        gender: string;
        religion: string;
        usedName: string;
    },
    licenses?: ProviderLicenseFromDataWithoutState;
    licensesInState?: ProviderLicenseFromData[];
    malpracticeInsuranceCertificate?: FormLicense;
    physicians?: FormPhysician[]
    seoCity?: string[]
    containSeoApp?: boolean,
    seoData?: Record<string, EditLocalSEOInfoServer>, //key is state
    payPlan?: TSubscriptionItemFromServer,
    listedChannels?: MyChannelsData,
    source?: 'A' | 'E',
    contractList?: IContract[],
};

export type HomeInfo = {
    firstChannelCompleted: boolean;
    practiceFrontCompleted: boolean;
    uniprofileApproved: boolean;
    uniprofileSubmitted: boolean;
    uniprofileApproveCompleted: boolean;
    ehrSetUpCompleted: boolean;
    agreementReviewTypeList: TSubscriptionType[];
    klarityStarterSetUpCompleted?: boolean;
    klarityUser?: boolean;
    klarityUserWithoutKiwiPlan?: boolean;
    freeUser?: boolean;
    providerPlan?: TSubscriptionItemFromServer;
    signUpFrom?: ESource,
    uniprofileStatus: EProfileStatus,
    addOnPaid?: boolean;
    firstLoginAt?: string;
    firstUniprofileApprovedAt?: string;
    firstUniprofileSubmitAt?: string;
    firstPracticeLaunchAt?: string;
    firstAddOnPaidAt?: string;
    ehrSetUpCompletedAt?: string;
};

export interface IUpdatePasswordFormData {
    email: string;
    password: string;
    oldPassword: string;
}

export interface ICommonFormData extends ServiceExt {
    hasSetPrice: boolean,
    hasSetMeetingType: boolean,
}

export enum PartOfNameEnmu {
    FIRSTNAME = 'firstName',
    LASTNAME = 'lastName',
}

export enum CouponType {
    HEADWAY = 'HEADWAY',
}

export interface ISales {
    accessToken?: string;
    bookingLinkUrl?: string;
    firstName: string;
    gmail?: string;
    id?: number;
    lastName: string;
    password?: string;
    tel: string;
    title: string;
}

export interface ISalesLinkCheckout {
    fullName: string;
    inviteEmail: string;
    message?: string;
    onboardingCallBookLink?: string;
    payPlan: TSubscriptionItemFromServer;
    presentationEmbeddedLink?: string;
    promotionCode?: string;
}

export interface ISalesProvider {
    accountReview: {
        emailList: string[],
        accountStatus: EBindType,
        loginEmail?: string,
        verify?: boolean,
    },
    currentStep: string;
    linkId: number;
    linkStatus: string;
    linkUuid: string;
    sales: ISales;
    checkOut: ISalesLinkCheckout;
    agreement: {
        contractId: number;
        contractUrl: string;
    },
    fullName?: string;
}

export interface ISalesCreateNewAccount {
    email: string;
    password?: string;
}

export type ProviderProfileShadow = Partial<ProviderProfile> & Partial<{
    // title?: string,
    // intro?: string,
    // primaryLanguage?: string,
    // foreignLanguage?: string[],
    // inPersonChecked?: boolean,
    // officeAddress?: string,
    // officeCity?: string,
    // officeState?: string,
    // officeZip?: string,
    // payout?: string,
    // payment?: string,
    // showReview?: boolean,
    // allowAuth?: boolean,
    // photoList?: string[],
    // additionalPhotoList?: string[],
    // inPersonVisitList?: unknown[],
    // telehealthVisitList?: unknown[],
    // licenses?: string[], //nurse string display?
    // status?: string,
    // listStatus?: string,
    // auditStatus?: string,
    // updateStatus?: string,
    // photo?: string,
    // website?: string,
    // middleName?: string,
    // headLine?: string,
    // credential?: string[],
    // specialityInHealth: string[],
    // specialityInFamily: string[],
    // npiNotRequired: boolean,
    titleNew?: string,
    introNew?: string,
    primaryLanguageNew?: string,
    officeAddressNew?: string,
    officeCityNew?: string,
    officeStateNew?: string,
    officeZipNew?: string,
    payoutNew?: string,
    paymentNew?: string,
    photoNew?: string,
    websiteNew?: string,
    middleNameNew?: string,
    headLineNew?: string,

    foreignLanguageNew?: string[],
    photoListNew?: string[],
    additionalPhotoListNew?: string[],
    licensesNew?: string[], //nurse string display?
    credentialNew?: string[],
    specialityInHealthNew: string[],
    specialityInFamilyNew: string[],
}>;

// export enum HealthcareExperienceFieldEnum {
//     ORGANIZATION_NAME = 'organizationName',
//     TITLE = 'title',
//     IS_CURRENTLY_WORK = 'currently',
//     START_DATE = 'startDate',
//     END_DATE = 'endDate',
//     EMPLOYMENT_TYPE = 'employmentType',
//     HOSPITAL_LOCATION = 'hospitalLocation',
//     DESCRIPTION = 'description',
//     LOGO = 'experienceLogo',
// }

export type ProviderChannelHealthcareExperienceShadow = ProviderChannelHealthcareExperience & Partial<{
    // [HealthcareExperienceFieldEnum.ORGANIZATION_NAME]: string,
    // [HealthcareExperienceFieldEnum.TITLE]: string,
    // [HealthcareExperienceFieldEnum.IS_CURRENTLY_WORK]: boolean,
    // [HealthcareExperienceFieldEnum.START_DATE]: string,
    // [HealthcareExperienceFieldEnum.END_DATE]: string,
    // [HealthcareExperienceFieldEnum.EMPLOYMENT_TYPE]: string,
    // [HealthcareExperienceFieldEnum.HOSPITAL_LOCATION]: string,
    // [HealthcareExperienceFieldEnum.DESCRIPTION]: string,
    // [HealthcareExperienceFieldEnum.LOGO]: string,
    organizationNameNew: string,
    titleNew: string,
    currentlyNew: boolean,
    startDateNew: string,
    endDateNew: string,
    employmentTypeNew: string,
    hospitalLocationNew: string,
    descriptionNew: string,
    experienceLogoNew: string,
}>;

export type FormLicenseShadow = FormLicense & Partial<{
    // id: number,
    // providerId: number,
    // type: string,
    // secondType: string,
    // state: string,
    // licenseNumber: string,
    // expireDate?: dayjs.Dayjs,
    // schedule: string[],
    // url?: string,
    // certificatedTitle?: string,
    // classTitle?: string,
    // hourCompleted?: number,
    // licenseType?: Record<string, string | Record<string, string>> // others wiil be string
    isNewAdd?: boolean,
    isDeleted?: boolean,
    typeNew?: string,
    secondTypeNew?: string,
    stateNew?: string,
    licenseNumberNew?: string,
    expireDateNew?: string,
    urlNew?: string,
    certificatedTitleNew?: string,
    classTitleNew?: string,
    hourCompletedNew?: number,
    scheduleNew?: string[],
}>;

export type ProviderLicenseFromDataWithoutStateShadow = {
    [ELicenseType.DEA]?: Array<FormLicenseShadow>;
    [ELicenseType.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]?: Array<FormLicenseShadow>;
    [ELicenseType.CONTROLLED_SUBSTAN_CECERTIFICATION]?: Array<FormLicenseShadow>;
    [ELicenseType.PRACTICE]?: Array<FormLicenseShadow>;
};

export type ProviderPracticeShadow = ProviderPractice & Partial<{
    treatmentPhilosophyNew: string;
    treatmentApproachNew: string;
    practiceNameNew: string;
    practiceAddressNew: string;
    practiceCityNew: string;
    practiceStateNew: string;
    practiceZipNew: string;
    practiceEmailNew: string;
    practicePhoneNew: string;
    practiceWebsiteNew: string;
    companyTypeNew: string;
    practiceDescNew: string;
    practiceLogoNew: string;
    specialityInFamilyNew?: string[];
    specialityInHealthNew?: string[];
    specialtyListNew: string[],
    conditionTreatedListNew: string[],
}>;

export type UniprofileFormDataShadow = UniprofileFormData & {
    profile: ProviderProfileShadow,
    practice: ProviderPracticeShadow,
    insurance: {
        insuranceList: Array<string>;
        insuranceListNew?: Array<string>;
        otherInsuranceList: string;
        otherInsuranceListNew?: string;
        supportPayType: string;
        supportPayTypeNew?: string;
    };
    licenses?: ProviderLicenseFromDataWithoutStateShadow;
    malpracticeInsuranceCertificate?: FormLicenseShadow;
    services: ServiceExtShadow[],
};

export interface IProvderAgreementSignFormData {
    'type': TSubscriptionType,
    'contractSignName': string,
    'contractSignDate': dayjs.Dayjs,
    'contractSignAddress': string,
}

export interface IProvderAgreementSignInput {
    'type': TSubscriptionType,
    'contractSignName': string,
    'contractSignDate': string,
    'contractSignAddress': string,
}

export interface IProvderAgreementSignResponse {
    contractId: number;
    contractPdfUrl: string;
    contractUrl: string;
    contractVersion: string;
    planType: TSubscriptionType;
}

export enum EProviderUniprofileStatus {
    PENDING_REVIEW = 'PR',
    LISTED = 'L',
    UNLISTED = 'U',
    DISQUALIFIED = 'D',
    UNIPROFILE_NEEDED = 'UN',
}
